@import "src/styles/variables";
@import "src/styles/mixins";

.about-text {
  background: $light-grey;
  padding: calc($header-height-mobile + 61px) 0 40px;

  .title {
    width: 322px;
    margin: 0 auto 29px;
    font-size: 28px;
    line-height: 31px;
    letter-spacing: -0.005em;
    color: $dark-grey;

    .highlight {
      position: relative;
      display: inline-block;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 22px;
        background: $green;
        margin-top: 9.5px;
        margin-left: -3.5px;
        padding: 0 4px;
        z-index: -1;
      }
    }
  }

  .subtext {
    width: 324px;
    margin: 0 auto;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: $dark-grey;
  }

  @include for-vertical-tablet-up {
    padding: calc($header-height-tablet + 93px) 0 54px;
    
    .title {
      width: 640px;
      margin: 0 auto 35px;
      font-size: 38px;
      line-height: 42px;

      .highlight::before {
        content: "";
        height: 31px;
        margin-top: 12px;
        margin-left: -5px;
        padding: 0 5.5px;
      }
    }

    .subtext {
      width: 636px;
      margin: 0 auto;
      font-size: 18px;
      line-height: 27px;
    }
  }

  @include for-desktop-up {
    padding: calc($header-height-desktop + 86px) 0 81px;

    .title {
      width: 953px;
      margin: 0 auto 31px;
      font-size: 56px;
      line-height: 62px;
      letter-spacing: -1px;
  
      .highlight::before {
        content: '';
        height: 44px;
        margin-top: 17px;
        margin-left: -9.5px;
        padding: 0px 9.5px;
      }
    }
  
    .subtext {
      width: 953px;
      margin: 0 auto;
    }
  }
}