@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: $light-grey;
  padding: 0 25px;

  .hero-container {
    margin: 60px 0 25px 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #18181833;
    position: initial;

    .breadcrumb-container {
      font-size: 16px;
      color: $dark-grey;
      margin: 25px 0 15px 0;
      height: 25px;
      display: flex;
      align-items: center;
    }

    .breadcrumb-chevron {
      width: 24px;
      margin: 0 5px;
    }

    .mint-green {
      color: $green;
    }

    .hero-title {
      font-size: 40px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.005em;
      color: $dark-grey;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
    }

    .hero-subtext {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: left;
      margin-bottom: 10px;
    }

    .hero-mobile {
      width: 100%;
      margin-bottom: 10px;
    }

    .hero-tablet {
      display: none;
    }
  }

  .connect-container {
    display: flex;
    flex-direction: column;
    margin: 25px 0;

    .connect-title {
      font-size: 28px;
      font-weight: 600;
      line-height: 30.8px;
      letter-spacing: -1px;
      text-align: left;
      margin-bottom: 15px;
    }

    .connect-mails {
      display: flex;
      flex-direction: column;
      .contact-card {
        border: 1px solid #18181833;
        border-radius: 20px;
        padding: 24px;
        gap: 4px;
        margin-bottom: 10px;

        .label {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          text-align: left;
          margin-bottom: 5px;
        }

        .mail-to {
          font-size: 24px;
          font-weight: 600;
          line-height: 34px;
          text-align: left;
          text-decoration: none;
          color: $dark-grey;
        }
      }
    }
  }

  .releases-container {
    width: initial;
    margin: 25px 0;

    .skeleton {
      width: 100%;
    }

    .card {
      padding: 30px;
      gap: 24px;
      border-radius: 20px;
      background: #ffffff;
      box-shadow: 2px 14px 22px 0px $black-translucent;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: 30px;

      .card-title {
        font-size: 28px;
        font-weight: 600;
        line-height: 30.8px;
        letter-spacing: -1px;
        text-align: left;
        color: $dark-grey;
        margin-bottom: 10px;
      }

      .card-subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        text-align: left;
        color: $mid-grey;
        margin-bottom: 15px;
      }

      .card-content {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        text-align: left;
        margin-bottom: 15px;
      }

      .card-cta {
        text-decoration: none;
        width: 100%;
        border: 1px solid $dark-grey;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 400px;
        color: $dark-grey;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: $green;
          border: transparent;
          cursor: pointer;
        }
      }
    }
  }
}

@include for-vertical-tablet-up {
  .container {
    padding: 0 50px;
    justify-content: center;
    align-items: flex-start;

    .hero-container {
      padding: 30px 0 50px;
      position: relative;
      width: 100%;

      .breadcrumb-container {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        text-align: left;
      }

      .hero-title {
        font-size: 50px;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: -1px;
        text-align: left;
      }

      .hero-subtext {
        font-size: 18px;
        font-weight: 400;
        line-height: 25.2px;
        text-align: left;
        width: 340px;
      }

      .hero-mobile {
        display: none;
      }

      .hero-tablet {
        display: block;
        position: absolute;
        right: 0;
        width: 350px;
      }
    }

    .connect-container {
      width: 100%;
      .connect-title {
        font-size: 32px;
        font-weight: 600;
        line-height: 35.2px;
        letter-spacing: -0.5px;
        width: 100%;
      }

      .connect-mails {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .contact-card {
          width: 335px;
          &:hover {
            background-color: #ffffff;
          }
        }
      }
    }

    .releases-container {
      width: 100%;
      margin: 15px 0 30px 0;

      .card {
        .card-title {
          font-size: 32px;
          font-weight: 600;
          line-height: 35.2px;
          letter-spacing: -1px;
          text-align: left;
          margin-bottom: 10px;
          width: 85%;
        }

        .card-subtitle {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          text-align: left;
          margin-bottom: 10px;
        }

        .card-content {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          text-align: left;
          margin-bottom: 10px;
        }

        .card-cta {
          width: 180px;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          text-align: left;
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .container {
    .hero-container {
      min-height: auto;
      .hero-subtext {
        width: 450px;
      }
    }
    .connect-container {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .connect-title {
        width: 330px;
      }

      .connect-mails {
        width: 600px;
        justify-content: flex-end;
        .contact-card {
          width: 280px;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: #ffffff;
          }
        }
      }
    }

    .releases-container {
      .card {
        .card-title {
          width: 60%;
        }
      }
    }
  }
}

@include for-desktop-up {
  .container {
    align-items: center;
    .hero-container {
      max-width: 1280px;
      min-height: 450px;
      justify-content: center;

      .breadcrumb-container {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        margin-bottom: 35px;
      }

      .hero-title {
        font-size: 78px;
        font-weight: 600;
        line-height: 78px;
        letter-spacing: -1px;
        text-align: left;
        margin-bottom: 25px;
      }

      .hero-subtext {
        width: 630px;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        text-align: left;
        margin-bottom: 25px;
      }

      .hero-tablet {
        width: 545px;
        top: 50px;
      }
    }
    .connect-container {
      max-width: 1065px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .connect-title {
        width: 330px;
      }

      .connect-mails {
        width: 600px;
        .contact-card {
          cursor: pointer;
          width: 280px;
        }
      }
    }

    .releases-container {
      max-width: 1065px;
      width: auto;

      .skeleton {
        width: 1065px;
      }

      .card {
        .card-title {
          width: 60%;
        }
      }
    }
  }
}
