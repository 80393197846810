@import "src/styles/variables";
@import "src/styles/mixins";

.partner-list {
  background: $light-grey;
  padding-bottom: 75px;

  .container {
    width: 338px;
    padding: 26px 8px 38px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 11px;
    background: $white;
    row-gap: 15px;
    box-shadow: 1px 7.5px 12px 0 $black-translucent;

    .title {
      width: 100%;
      font-size: 11px;
      line-height: 27px;
      letter-spacing: 2.6px;
      text-align: center;      
      color: $dark-grey;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    .partner {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 107px;
      row-gap: 3px;

      img {
        width: 63px;
        height: 63px;       
      }

      p {
        font-size: 8px;
        line-height: 11px;
        letter-spacing: 0.005em;
        text-align: center;
        color: $mid-grey;
      }
    }
  }

  @include for-vertical-tablet-up {
    padding-bottom: 93px;

    .container {
      width: 640px;
      padding: 36px 24px 80px;
      box-shadow: 2px 14px 22px 0 $black-translucent;
      row-gap: 22px;
      align-items: center;

      .title {
        font-size: 16px;
        line-height: 50px;
        margin-bottom: 5px;
      }

      .partner {
        min-width: 197px;
        row-gap: 6px;

        img {
          width: 105px;
          height: 105px;         
        }

        p {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }

  @include for-desktop-up {
    padding-bottom: 126px;

    .container {
      width: 1100px;
      padding: 36px 33px 58px;
      box-shadow: 2px 14px 22px 0px $black-translucent;
      flex-direction: row;

      .title {
        font-size: 16px;
        line-height: 50px;
        margin-bottom: 0;
      }

      .partner {
        flex: 1;
        row-gap: 8px;

        img {
          width: 115px;
          height: 115px;
        }

        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
