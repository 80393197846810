@import "src/styles/variables";
@import "src/styles/mixins";

.logo {
  color: inherit;
  text-decoration: none;
  .raise-logo {
    width: 72px;
    height: 24px;

    path {
      transition: fill 0.2s;
      fill: $green;
    }

    @include for-vertical-tablet-up {
      width: 92px;
      height: 30px;
    }

    @include for-desktop-up {
      width: 137px;
      height: 45px;
    }
  }
  .raise-short-logo {
    width: 55px;
    height: 50px;
    path {
      transition: fill 0.2s;
      fill: $green;
    }
  }
}

[data-theme="business"] {
  .logo {
    .raise-logo,
    .raise-short-logo {
      path {
        fill: $black;
      }
    }
  }
}
