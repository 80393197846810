@import "src/styles/variables";
@import "src/styles/mixins";

$background-left-color: #8e3fee;
$backgorund-right-color: #1e0673;

.big-numbers-section {
  margin-top: $header-height-mobile;
  padding: 10px 0px 20px;
  .big-numbers-description {
    text-align: center;
    max-width: 730px;
    margin: 0 auto;
    .title {
      margin-bottom: 26px;
    }
    .dark-grey {
      color: $dark-grey;
    }
  }
  .big-numbers-container {
    margin-top: 30px;
    display: grid;
    @include for-mobile-only {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
    @include for-vertical-tablet-up {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      margin-top: 50px;
    }
    @include for-desktop-up {
      margin-top: $header-height-desktop;
    }
  }

  @include for-vertical-tablet-up {
    padding: 0 0 130px;
  }
  @include for-desktop-up {
    padding: 60px 0 130px;
  }
}

[data-theme="business"] {
  .big-numbers-section {
    .big-numbers-description {
      .dark-grey {
        background: linear-gradient(90deg, $background-left-color, $backgorund-right-color);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include for-vertical-tablet-up {
          background: unset;
          background-clip: unset;
          -webkit-background-clip: unset;
          -webkit-text-fill-color: unset;
        }
      }
    }
  }
}
