@import "src/styles/variables";
@import "src/styles/mixins";

.future-slider-dots {
  &.carousel-dots {
    margin-bottom: 18px;
    @include for-vertical-tablet-up {
      margin-bottom: 21px;
    }
  }
}

.future-slider-settings {
  .slick-list {
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    @include for-tablet-landscape-up {
      box-shadow: none;
      border-radius: 0;
      height: 583px;
    }
    @include for-desktop-up {
      height: 712px;
    }
    @include for-large-desktop-up {
      height: 762px;
    }
  }
}
