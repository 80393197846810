@import "src/styles/variables";
@import "src/styles/mixins";

.social-links-area {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  margin-bottom: 32px;
  .social-link-image {
    width: 30px;
    height: 30px;
  }
  @include for-desktop-up {
    margin-bottom: 50px;
    .social-link-image {
      width: 48px;
      height: 48px;
    }
  }
}
