.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #f7f8fc;
  margin-top: 60px;
  padding: 40px;
}

.content {
  width: 100%;
  display: block;
}

.contentHeader {
  width: 100%;
  color: #01005e;
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 5px;
}

.subtext {
  color: var(--grayscale-500, #404040);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 20px;
}

.mainContent {
  width: 100% - 40px;
  padding: 0 16px 0 24px;
  color: var(--grayscale-500, #404040);
  font-family: 'Averta Std', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.pointParagraph {
  margin-top: 0;
}
.mainContentTopic {
  color: var(--text-royal-blue, #01005e);
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.link {
  color: var(--primary-raise-purple-500-primary, #5020f7);
}

.mainContentSubtopic {
  color: var(--grayscale-500, #404040);
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.subContentTopic {
  color: var(--text-royal-blue, #01005e);
  font-family: 'Averta Std', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.numberedList,
.bulletList {
  padding-left: 15px;
}

.bullet::marker {
  font-size: 10px;
}

.footer {
  width: 100vw;
  background-color: #000035;
  padding: 30px 0 15px 0;
}

.footerParagraph {
  color: #fff;
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin: 0 16px 15px 15px;
}

.highlight {
  color: var(--primary-sea-green-500-primary, #01d6a2);
  font-family: 'Averta Std', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.pillsContainer {
  padding: 25px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: block;
}

.pillsWrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 28px;
}

.pillsWrapper::-webkit-scrollbar {
  display: none;
}

.pill {
  border-radius: 40px;
  background: #fff;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.052px;
  opacity: 0.35;
  color: var(--text-royal-blue, #01005e);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  transition: opacity 0.2s ease-in-out;
  padding: 5px 10px;
  margin: 0 10px;
  white-space: nowrap;
  flex-shrink: 0;
}

.activePill {
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .mainContent {
    padding: 0 30px 0 40px;
    max-width: auto;
  }

  .footer {
    display: block;
  }

  .footerParagraph {
    margin: 0 30px 30px 30px;
    max-width: auto;
  }
}

@media only screen and (min-width: 1150px) {
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .mainContent {
    padding: 0;
    max-width: 1024px;
  }

  .footer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .footerParagraph {
    margin: 0;
    max-width: 1232px;
    margin-bottom: 30px;
    text-align: left;
  }

  .pillsContainer {
    display: flex;
    justify-content: center;
  }

  .pillsWrapper {
    width: 1024px;
  }
}
