@import "src/styles/variables";
@import "src/styles/mixins";

.slider-arrows-container {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  width: auto;

  .arrow-button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.right-arrow {
      transform: rotate(180deg);
    }
  }
}
