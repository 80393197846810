@import "fonts";

// fonts
$font-regular: "AvertaStd-Regular", Helvetica;
$font-semibold: "AvertaStd-Semibold", Helvetica;
// TODO: replace with alternate font-face
$font-regular-secondary: "AvertaStd-Regular", Helvetica;
$font-semibold-secondary: "AvertaStd-Semibold", Helvetica;

// main colors
$white: #FFF;
$black: #000;
$grey: #DDDDDD;
$light-grey: #F5F5F5;
$lightest-grey: #F9F9F9;
$mid-grey: #656565;
$dark-grey: #181818;
$black-translucent: #0000001A;

// secondary colors
$green: #1AEDBA;
$light-green: #0BFCC2;
$lemon-green: #C9F721;
$purple: #C7A5FF;
$light-purple: #CDAEFF;
$orange: #FC5130;
$red: #E71D36;
$yellow: #F9B61E;
$mid-blue: #0000DD;
$dark-blue: #0000A6;

// breakpoints width sizes
$large-desktop: 1920px;
$desktop: 1440px;
$tablet-landscape: 1024px;
$tablet-vertical: 768px;
$mobile: 375px;

// sizes
$header-height-mobile: 60px;
$header-height-tablet: 70px;
$header-height-desktop: 90px;
