@import "src/styles/variables";
@import "src/styles/mixins";

.informational-text {
  padding: 70px 0;
  background: $dark-grey;

  span {
    white-space: nowrap;
  }

  .pill {
    display: inline-block;
    height: 36px;
    width: 160px;
    font-size: 21px;
    border-radius: 70px;
    background: $green;
    color: $dark-grey;
    font-weight: 600;
    line-height: 32px
  }

  h3.title-md {
    white-space: pre;
    max-width: 390px;
    margin: auto;
    color: $light-grey;
    text-align: center;
    font-weight: 400;
    font-size: 26px;
    letter-spacing: -0.015em;
  }

  @include for-vertical-tablet-up {
    .pill {
      width: 178px;
      height: 40px;
      font-size: 24px;
      line-height: 38px;
      text-align: center;
    }

    h3.title-md {
      max-width: 678px;
      font-size: 28px;
      font-weight: 400;
      line-height: 46px;
      letter-spacing: -0.01em;
    }
  }

  @include for-desktop-up {
    padding: 117px 0;

    .pill {
      width: auto;
      height: 51px;
      font-size: 32px;
      padding: 0 27px;
      line-height: 43px;
    }

    h3.title-md {
      max-width: 980px;
      font-size: 40px;
      line-height: 60px;
      letter-spacing: -0.001em;
    }
  }
}

[data-theme="business"] {
  .pill {
    background: $purple;
  }
}
