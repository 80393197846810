@import "src/styles/variables";
@import "src/styles/mixins";

.qr-code-container {
  display: none;
  background-color: $white;
  width: 495px;
  height: 160px;
  border-radius: 20px;
  padding: 22px 20px;
  margin-top: 40px;
  strong {
    font-family: $font-semibold;
  }
  .raise-earn {
    &:visited {
      color: inherit;
    }
  }
  .qr-code {
    width: auto;
    height: 100%;
  }
  @include for-desktop-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
  }
}
