@import "src/styles/variables";
@import "src/styles/mixins";

.business-link {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  height: 51px;

  .image-container {
    width: 78px;
    height: 100%;
    .business-image {
      height: 100%;
      width: auto;
    }
  }

  .business-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 55px;
    .business-title {
      color: $white;
      font-size: 19px;
      line-height: 26px;
    }
    .learn-more-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      color: $light-grey;
    }
  }
}

@include for-vertical-tablet-up {
  .business-link {
    .business-description {
      .business-title {
        font-size: 18px;
        line-height: 19.8px;
      }
      .learn-more-button {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}

@include for-desktop-up {
  .business-link {
    min-height: 89px;
    height: auto;
    align-items: flex-start;
    .image-container {
      max-width: 90px;
      .business-image {
        width: 100%;
      }
    }
    .business-description {
      justify-content: center;
      gap: 20px;
      .business-title {
        font-size: 26px;
        line-height: 34px;
        max-width: 197px;
      }
      .learn-more-button {
        font-size: 18px;
      }
    }
  }
}
