@import "./styles/reset";
@import "./styles/variables";

body {
  font-family: $font-regular;
  overflow-x: hidden;

  &:has(.disable-overflow) {
    overflow: hidden;
  }
}
