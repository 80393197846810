@import "src/styles/variables";

.carousel-dots {
  margin: 28px auto 0;
  display: flex;
  justify-content: space-between;
  width: 80px;
  height: 10px;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: $grey;
    transition: background-color 0.2s;

    &.active {
      width: 16px;
      background-color: $green;
    }
  }
}

[data-theme="business"] {
  .carousel-dots {
    .dot {
      &.active {
        background-color: $purple;
      }
    }
  }
}
