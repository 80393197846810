@import "src/styles/variables";
@import "src/styles/mixins";

.future-slide-card {
  width: 100%;
  height: 780px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  .thumbnail-head {
    height: 352px;
    width: 350px;
    .thumbnail-image {
      height: auto;
      width: 100%;
    }
  }

  .description-area {
    padding: 0 30px;
    .subtitle {
      font-size: 15px;
      line-height: 30px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .title {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -1px;
      margin-bottom: 9px;
    }
    .text {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.005em;
      text-align: left;
    }
  }

  @include for-vertical-tablet-up {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: flex-start;
    height: 553px;
    .thumbnail-head {
      order: 1;
    }
    .description-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 63px 0 55px 51px;
    }
  }

  @include for-tablet-landscape-up {
    max-width: 700px;
    border-radius: 20px;
    box-shadow: 2px 14px 22px 0px rgba(0, 0, 0, 0.1);
  }

  @include for-desktop-up {
    height: 683px;
    max-width: 92%;
    .thumbnail-head {
      width: 100%;
      height: 100%;
      padding-top: 13px;
    }
    .description-area {
      padding: 84px 0 74px 80px;
      .subtitle {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.8px;
      }
      .title {
        font-size: 58px;
        line-height: 63.8px;
        letter-spacing: -1px;
        margin: 3px 0 23px;
      }
      .text {
        font-size: 18px;
        line-height: 28.8px;
      }
    }
  }

  @include for-large-desktop-up {
    height: 700px;
  }
}
