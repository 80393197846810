@import "src/styles/variables";
@import "src/styles/mixins";

.footer-menu {
  padding-top: 36px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .menu-items {
    margin-bottom: 24px; 
    .group-title, 
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .anchor-menu-item {
      font-family: $font-regular;
      color: $dark-grey;
      font-size: 18px;
      line-height: 28px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include for-mobile-only {
    .social-links-area {
      align-self: flex-end;
      margin-bottom: 24px;
    }
  }

  @include for-vertical-tablet-up {
    justify-content: flex-end;
    gap: 30px;
    padding-top: 0;
    padding-left: 24px;

    .menu-items {
      .group-title,
      .anchor-menu-item {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }

  @include for-desktop-up {
    grid-template-columns: repeat(3, 1fr);
    gap: 90px;
    padding-left: 0;

    .menu-items {
      .group-title,
      .anchor-menu-item {
        font-size: 20px;
        line-height: 30px;
      }

      .group-title {
        margin-bottom: 40px;
      }

      .anchor-menu-item {
        margin-bottom: 18px;
      }
    }
  }
}
