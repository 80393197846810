@import "src/styles/variables";
@import "src/styles/mixins";

.menu-container {
  width: 85px;
  display: flex;
  cursor: pointer;
}

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-right: 15px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }
}

.nav-container {
  padding-top: 2px;

  .nav-icon {
    width: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $dark-grey;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0px;
    }
    span:nth-child(2),
    span:nth-child(3) {
      top: 8px;
    }
    span:nth-child(4) {
      top: 16px;
    }

    &.open {
      span:nth-child(1) {
        top: 0px;
        width: 0%;
        left: 20%;
      }
      span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      span:nth-child(4) {
        top: 16px;
        width: 0%;
        left: 20%;
      }
    }
  }
}

.nav-modal {
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  width: 100%;
  opacity: 0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $light-grey;
  flex-direction: column;
  padding-bottom: 100px;

  &.visible {
    opacity: 1;
    overflow: auto !important;
  }

  .menu-link-container {
    width: 345px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid $black;
    font-size: 28px;
    font-weight: 600;
    line-height: 30.8px;
    letter-spacing: -1px;
    text-align: left;
    margin-bottom: 15px;
    padding-bottom: 20px;

    &.active {
      opacity: 0.48;
    }

    &.menu-link {
      cursor: pointer;
    }
    .menu-arrow {
      width: 22px;
    }
  }

  .section-name {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.3px;
    letter-spacing: 0.05em;
    text-align: left;
    width: 345px;
    margin-top: 30px;
    text-transform: uppercase;
    margin-bottom: 15px;
    cursor: initial;
  }

  @include for-small-mobile-only {
    padding-bottom: 10px;
  }

  @include for-small-mobile-landscape-only {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include for-vertical-tablet-up {
    top: 70px;
    justify-content: flex-start;
    padding-top: 100px;

    .menu-link-container,
    .section-name {
      width: 510px;
    }

    .menu-link-container {
      font-size: 32px;
      font-weight: 600;
      line-height: 35.2px;
      letter-spacing: -0.5px;
      text-align: left;
    }

    .section-name {
      font-size: 16px;
      font-weight: 400;
      line-height: 19.78px;
      letter-spacing: 0.05em;
      text-align: left;
    }
  }

  @include for-tablet-landscape-up {
    .menu-link-container,
    .section-name {
      width: 70%;
    }
  }

  @include for-desktop-up {
    top: 90px;
  }
}
