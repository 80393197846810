@import "src/styles/mixins";

.grid-container {
  max-width: 100%;
  padding: 0 17px;

  @include for-vertical-tablet-up {
    padding: 0 32px;
  }

  @include for-desktop-up {
    padding: 0 80px;
  }

  @include for-large-desktop-up {
    padding: 0 162px;
  }
}