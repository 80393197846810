@import "./variables";

@mixin for-small-mobile-only {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin for-small-mobile-landscape-only {
  @media (orientation: landscape) and (max-width: $tablet-vertical) {
    @content;
  }
}

@mixin for-mobile-only {
  @media (max-width: $tablet-vertical) {
    @content;
  }
}

@mixin for-vertical-tablet-only {
  @media (min-width: $tablet-vertical) and (max-width: calc($tablet-landscape - 1px)) {
    @content;
  }
}

@mixin for-vertical-tablet-up {
  @media (min-width: $tablet-vertical) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: $tablet-landscape) {
    @content;
  }
}

@mixin for-tablet-landscape-only {
  @media (min-width: $tablet-landscape) and (max-width: calc($desktop - 1px)) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (min-width: $desktop) and (max-width: calc($large-desktop - 1px)) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin for-large-desktop-up {
  @media (min-width: $large-desktop) {
    @content;
  }
}
