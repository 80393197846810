.container {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -200px;
}

.section {
  width: 100%;
}

.slideContainer {
  width: 100%;
  display: flex !important;
  justify-content: center;
}

.slide {
  height: 590px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 14px;
  background: var(--White, #fff);
  position: static;
  position: initial;
  margin: 0 10px 25px 10px;
  box-shadow: 3.8375px 12.79167px 15.98958px 0px rgba(39, 27, 97, 0.1);
}

.slideTitle {
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
  letter-spacing: -0.28px;
  margin: 35px 0 15px 0;
}

.slideImageMobile {
  width: 280px;
  margin-bottom: 25px;
}

.slideContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
}

.slideSubtitle {
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px;
  margin-bottom: 10px;
}

.slideSubtext {
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  width: 273px;
}

.slideSubtextBold {
  color: var(--Black, #181818);
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.arrowButton {
  height: 40px;
  width: 20px;
  outline: transparent;
  border: transparent;
  background: transparent;
  position: absolute;
  bottom: 50%;
  z-index: 1;
}

.buttonLeft {
  left: 5%;
}

.buttonRight {
  right: 8%;
}

.arrowRight,
.arrowLeft {
  height: 40px;
  width: 20px;
  cursor: pointer;
}

.arrowRight {
  right: 0;
  content: url('../../../assets/earn/right-chevron.png');
}

.arrrowLeft {
  left: 0;
  content: url('../../../assets/earn/left-chevron.png');
}

.dots {
  display: flex !important;
  list-style-type: none;
  width: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 50px;
}

.dots li {
  width: 10px;
  margin: 0 10px;
}

.dots li button {
  height: 10px;
  width: 100%;
  border-radius: 50%;
  background: #e5e5f1;
  outline: transparent;
  border: transparent;
  margin: 0 10px;
  font-size: 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

:global(li.slick-active) {
  width: 20px !important;
  transition: all 0.3s ease-in-out;
}

:global(li.slick-active button) {
  border-radius: 72.486px;
  background: #bbbbc3;
}

@media only screen and (min-width: 768px) {
  .container {
    bottom: -350px;
  }

  .buttonLeft {
    left: 8%;
  }

  .buttonRight {
    right: 9.5%;
  }
  .slide {
    width: 85%;
    height: 475px;
    position: relative;
  }

  .slideTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.32px;
    margin-top: 55px;
  }

  .slideContentContainer {
    align-items: flex-start;
    width: 95%;
    margin: 50px 0 0 85px;
  }

  .slideSubtitle {
    color: var(--Black, #181818);
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.8px;
    text-align: left;
  }

  .slideSubtext {
    color: var(--Black, #181818);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    width: 280px;
  }

  .slideSubtextBold {
    color: var(--Black, #181818);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  .slideImageTablet {
    position: absolute;
    bottom: 70px;
    right: 50px;
    width: 300px;
  }
}

@media only screen and (min-width: 1150px) {
  .container {
    width: 100%;
  }
  .section {
    width: 1130px;
  }
  .buttonLeft {
    left: 3%;
  }

  .buttonRight {
    right: 4.5%;
  }
  .slide {
    width: 1080px;
    height: 700px;
    box-shadow: 6px 20px 25px 0px rgba(39, 27, 97, 0.1);
    border-radius: 18px;
    margin: 0 10px 35px 10px;
  }

  .slideTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.48px;
    margin: 70px 0 85px 0;
  }

  .slideImageTablet {
    width: 490px;
    bottom: 60px;
    right: 95px;
  }

  .slideContentContainer {
    margin-left: 125px;
  }

  .slideSubtitle {
    color: var(--Black, #181818);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 20px;
  }

  .slideSubtext {
    color: var(--Black, #181818);
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 31.5px */
    width: 398px;
  }

  .slideSubtextBold {
    color: var(--Black, #181818);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
}
