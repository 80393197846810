@import "src/styles/variables";
@import "src/styles/mixins";

.secondary-area-content {

  .consumer-area {
    display: none;
  }

  .business-area {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 320px;
    margin: 0 auto;

    hr {
      border: 1px solid $white;
    }
  }
}

@include for-vertical-tablet-up {
  .secondary-area-content {
    .business-area {
      width: 225px;
    }

    .rewards-link {
      margin-bottom: 16px;
      .image-container {
        width: 120px;
        .business-image {
          width: 68px;
        }
      }
    }
  }
}

@include for-desktop-up {
  .secondary-area-content {
    .consumer-area {
      display: block;
      .consumer-image {
        width: 340px;
      }
    }
    .business-area {
      width: 385px;
      margin-top: 33px;
      gap: 30px;
    }
  }
  .rewards-link {
    .image-container {
      .business-image {
        width: 70px;
      } 
    }
  }
}
