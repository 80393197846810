@import "src/styles/mixins";
@import "src/styles/variables";

.typography {
  letter-spacing: -1px;
  font-family: $font-regular;
  font-weight: normal;

  &.semibold {
    font-family: $font-semibold;
    font-weight: bold;
  }

  &.title-sm,
  &.title-xsm,
  &.body-lg,
  &.body-md,
  &.body-sm {
    font-family: $font-regular-secondary;
    font-weight: normal;

    &.semibold {
      font-family: $font-semibold-secondary;
      font-weight: bold;
    }
  }

  &.title-xsm,
  &.body-lg,
  &.body-md,
  &.body-sm {
    letter-spacing: 0;
  }

  &.title-xxl {
    @include for-mobile-only {
      font-size: 44px;
      line-height: 44px;
    }
    @include for-vertical-tablet-up {
      font-size: 60px;
      line-height: 60px;
    }
    @include for-desktop-up {
      font-size: 80px;
      line-height: 80px;
    }
    @include for-large-desktop-up {
      font-size: 90px;
      line-height: 90px;
    } 
  }
  
  &.title-xl {
    @include for-mobile-only {
      font-size: 38px;
      line-height: 38px;
    }
    @include for-vertical-tablet-up {
      font-size: 48px;
      line-height: 48px;
    }
    @include for-desktop-up {
      font-size: 60px;
      line-height: 60px;
    }
    @include for-large-desktop-up {
      font-size: 70px;
      line-height: 70px;
    }
  }
  
  &.title-lg {
    @include for-mobile-only {
      font-size: 32px;
      line-height: 38px;
    }
    @include for-vertical-tablet-up {
      font-size: 36px;
      line-height: 44px;
    }
    @include for-desktop-up {
      font-size: 40px;
      line-height: 50px;
    }
    @include for-large-desktop-up {
      font-size: 50px;
      line-height: 60px;
    }
  }
  
  &.title-md {
    @include for-mobile-only {
      font-size: 32px;
      line-height: 43px;
    }
    @include for-vertical-tablet-up {
      font-size: 36px;
      line-height: 54px;
    }
    @include for-desktop-up {
      font-size: 40px;
      line-height: 60px;
    }
    @include for-large-desktop-up {
      font-size: 50px;
      line-height: 70px;
    }
  }
  
  &.title-sm {
    @include for-mobile-only {
      font-size: 26px;
      line-height: 32px;
    }
    @include for-vertical-tablet-up {
      font-size: 30px;
      line-height: 36px;
    }
    @include for-desktop-up {
      font-size: 32px;
      line-height: 38px;
    }
    @include for-large-desktop-up {
      font-size: 44px;
      line-height: 54px;
    }
  }
  
  &.title-xsm {
    @include for-mobile-only {
      font-size: 22px;
      line-height: 30px;
    }
    @include for-vertical-tablet-up {
      font-size: 24px;
      line-height: 32px;
    }
    @include for-desktop-up {
      font-size: 26px;
      line-height: 34px;
    }
    @include for-large-desktop-up {
      font-size: 30px;
      line-height: 40px;
    }
  }
  
  &.body-lg {
    @include for-mobile-only {
      font-size: 18px;
      line-height: 28px;
    }
    @include for-vertical-tablet-up {
      font-size: 18px;
      line-height: 28px;
    }
    @include for-desktop-up {
      font-size: 20px;
      line-height: 30px;
    }
    @include for-large-desktop-up {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &.body-md {
    @include for-mobile-only {
      font-size: 16px;
      line-height: 24px;
    }
    @include for-vertical-tablet-up {
      font-size: 16px;
      line-height: 24px;
    }
    @include for-desktop-up {
      font-size: 18px;
      line-height: 26px;
    }
    @include for-large-desktop-up {
      font-size: 18px;
      line-height: 26px;
    }
  }
  
  &.body-sm {
    @include for-mobile-only {
      font-size: 14px;
      line-height: 22px;
    }
    @include for-vertical-tablet-up {
      font-size: 14px;
      line-height: 22px;
    }
    @include for-desktop-up {
      font-size: 16px;
      line-height: 24px;
    }
    @include for-large-desktop-up {
      font-size: 16px;
      line-height: 24px;
    }
  }  
}
