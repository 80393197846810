@import "src/styles/variables";
@import "src/styles/mixins";

.big-number-card {
  box-shadow: 0px 10px 22px 0px rgba(0,0,0, 0.05);
  width: 170px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  margin: 0 auto;
  .big-number {
    position: relative;
    z-index: 3;
    &::before {
      content: "";
      position: absolute;
      left: -6px;
      bottom: 0;
      z-index: -1;
      width: 63px;
      height: 25px;
      background: $light-green;
    }
    &.small {
      &::before {
        width: 36px;
      }
    }
    &.medium {
      &::before {
        width: 93px;
      }
    }
    &.big {
      &::before {
        width: 105px;
      }
    }
  }
  .description {
    margin-top: 11px;
    font-size: 14px;
    line-height: 17px;
  }
  @include for-vertical-tablet-up {
    .big-number {
      margin-bottom: 11px;
      font-size: 39px;
      line-height: 39px;
    }
    .description {
      margin-top: 0;
    }
  }
  @include for-desktop-up {
    width: 290px;
    height: 230px;

    .big-number {
      font-size: 70px;
      line-height: 70px;
      &::before {
        height: 44px;
      }
      &.small {
        &::before {
          width: 63px;
        }
      }
      &.medium {
        &::before {
          width: 165px;
        }
      }
      &.big {
        &::before {
          width: 186px;
        }
      }
    }

    .description {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

[data-theme="business"] {
  .big-number-card {
    .big-number {
      &::before {
        background-color: $light-purple;
      }
    }
  }
}
