@import "src/styles/variables";
@import "src/styles/mixins";

.community-button {
  width: 100px;
  
  .name {
    line-height: 28px;
    text-align: center;
    margin-bottom: 4px;
    color: $dark-grey;
  }

  button {
    width: 100px;
    height: 100px;

    svg path {
      fill: $dark-grey;
    }
  }

  @include for-desktop-up {
    width: 120px;

    .name {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 10px;
    }

    button {
      width: 120px;
      height: 120px;
    }
  }
}