@import "src/styles/mixins";
@import "src/styles/variables";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: $header-height-mobile;
  background: $white;
  z-index: 10;

  &:not(.top-screen) {
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 1px 1px 0px $black-translucent;
    backdrop-filter: blur(5px);

    .dropdown {
      background: transparent;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .grid-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown {
    display: block;
    background: $white;

    .dropdown-button {
      display: flex;
      align-items: center;
      width: 90px;
      
      .arrow {
        margin: 0 0 0 12px;
        width: 12px;
        height: 7px;
        transform: rotate(180deg);
        transition: transform 0.2s;
      }
    }

    hr {
      width: 100%;
      height: 1px;
      background: $dark-grey;

      &:first-of-type {
        display: none;
      }
    }
  
    .dropdown-content {
      height: 295px;
      padding: 0 20px 20px;
      top: 60px;
      left: 0;
      background: $white;
      width: 100%;
      position: absolute;
      z-index: 10;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.2s;

      p {
        margin: 17px 0;
      }
    }
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $dark-grey;
    visibility: hidden;
    opacity: 0;
  }

  &.dropdown-open {
    background: $white;

    .dropdown-button .arrow {
      transform: rotate(0deg);
    }

    .dropdown-content {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 0.2s;
    }

    .backdrop {
      visibility: visible;
      opacity: 0.2;
      transition: visibility 0s, opacity 0.5s;
    }
  }
  
  @include for-vertical-tablet-up {
    height: $header-height-tablet;

    .dropdown {
      position: relative;
      width: 175px;
      height: 70px;
      padding-right: 32px;

      .dropdown-button {   
        width: 175px;
        padding: 20px;
        
        .arrow {
          margin: 4px 24px 0;
          width: 16px;
          height: 9px;
          transform: rotate(180deg);
        }
      }

      .dropdown-content {
        width: 175px;
        height: 250px;
        padding: 20px;
        opacity: 0;

        hr:first-of-type {
          display: block;
        }

        p {
          margin: 12.5px 0;
        }
      }
      
      &:hover {
        filter: drop-shadow(0px 10px 20px $black-translucent);
        transition: filter 0.2s;

        .dropdown-content {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s, opacity 0.2s;
        }

        .arrow {
          transform: rotate(0deg);
        }
      }
    }

    &:not(.top-screen) .dropdown:hover {
      background: white;
    }

    .backdrop {
      display: none;
    }
  }

  @include for-desktop-up {
    height: $header-height-desktop;

    .dropdown {
      height: 78px;
      margin-top: 12px;
    }
  }
}
