@import "src/styles/variables";
@import "src/styles/mixins";

.copyright {
  width: 100%;
  height: 92px;
  background-color: $light-grey;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .copyright-text {
    max-width: 250px;
    .copyright-anchor {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include for-vertical-tablet-up {
    height: 50px;
    justify-content: center;
    .copyright-text {
      max-width: none;
    }
  }

  @include for-desktop-up {
    height: 64px;
    .copyright-year {
      text-transform: capitalize;
    }
  }
}
