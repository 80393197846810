@import "src/styles/variables";
@import "src/styles/mixins";

.community-banner {
  height: 572px;
  width: 100%;
  background: $green;
  position: relative;
  overflow: hidden;

  .background-letter {
    width: 712px;
    height: 648px;
    position: absolute;
    bottom: -36px;
    left: 50%;
    translate: calc(-50% + 11%);
  }

  .community-banner-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    .title {
      font-size: 38px;
      text-align: center;
      margin: 121px 0 24px;
      color: $dark-grey;
    }

    .description {
      width: 324px;
      text-align: center;
      color: $dark-grey;
    }

    .community-buttons {
      display: flex;
      justify-content: center;
      height: 138px;
      margin-top: 45px;

      hr {
        width: 1px;
        height: 130px;
        background: $dark-grey;
        margin: 0 40px;
      }
    }
  }

  @include for-mobile-only {
    .background-letter path {
      fill: $light-green;
    }
  }

  @include for-vertical-tablet-up {
    height: 539px;
    overflow: visible;
    overflow-x: clip;

    .background-letter {
      width: 736px;
      height: 670px;
      translate: -50%;
      bottom: -14px;

      path {
        fill: url(#tablet-gradient);
      }
    }

    .community-banner-content {
      .title {
        font-size: 52px;
        line-height: 44px;
        text-align: center;
        margin: 132px 0 24px;
      }

      .description {
        width: 580px;
        font-size: 20px;
        line-height: 32px;
      }

      .community-buttons {
        margin-top: 50px;

        .community-button:nth-of-type(1) svg {
          width: 71px;
          height: 56px;
        }
    
        .community-button:nth-of-type(2) svg {
          width: 75px;
          height: 63px;
        }
      }
    }
  }

  @include for-desktop-up {
    height: 677px;

    .background-letter {
      width: 1215px;
      height: 1104px;
      bottom: -77px;
      
      path {
        fill: url(#desktop-gradient);
      }
    }

    .community-banner-content {
      .title {
        font-size: 62px;
        line-height: 80px;
        margin: 177px 0 17px;
      }

      .description {
        width: 1200px;
        font-size: 22px;
        line-height: 30px;
      }

      .community-buttons {
        margin-top: 66px;

        hr {
          height: 150px;
          margin: 0 48px;
        }

        .community-button:nth-of-type(1) svg {
          width: 85px;
          height: 66px;
        }
  
        .community-button:nth-of-type(2) svg {
          width: 90px;
          height: 75px;
        }
      }
    }
  }
}

[data-theme="business"] {
  .community-banner {
    background: $purple;
    .background-letter path {
      fill: $light-purple;
    }
    
    @include for-vertical-tablet-up {
      .background-letter path {
        fill: url(#tablet-gradient);
      }
      svg.background-letter stop:nth-child(2) {
        stop-color: $light-purple;
      }
    }
    @include for-desktop-up {
      .background-letter path {
        fill: url(#desktop-gradient);
      }
    }
  }
}
