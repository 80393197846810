@font-face {
  font-family: "AvertaStd-Regular";
  src: url("/assets/fonts/Averta-Regular.otf");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "AvertaStd-Semibold";
  src: url("/assets/fonts/Averta-Semibold.otf");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}