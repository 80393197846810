@import "src/styles/variables";
@import "src/styles/mixins";

.hero-scene {
  background-image: url('/assets/images/consumer-mobile-bg.png');
  background-size: cover;
  background-position: bottom;
  transition: background-image 0.2s;
  margin-top: $header-height-mobile;
  height: 505px;
  transition: height 0.2s;
  position: relative;
  padding-top: 25px;
  .hero-scene-cover {
    background: url('/assets/curve-mobile.svg') no-repeat bottom;
    background-size: 100%;
    width: 100%;
    height: 100%;
    .toggle-area {
      display: flex;
      justify-content: center;
    }
    .content-area-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 50px;
    }
  }
}

@include for-vertical-tablet-up {
  .hero-scene {
    background-position: 40% top;
    background-image: url('/assets/images/consumer-desktop-bg.png');
    padding-top: 41px;
    .hero-scene-cover {
      .toggle-area {
        justify-content: flex-start;
        padding-left: 60px;
      }
      .content-area-container {
        padding-left: 60px;
        align-items: center;
        flex-direction: row;
      }
      background-image: url('/assets/curve-desktop.svg');
    }
  }
}

@include for-desktop-up {
  .hero-scene {
    background-position: bottom;
    margin-top: $header-height-desktop;
    padding-top: 80px;
    height: 749px;
    .hero-scene-cover {
      padding-left: 100px;
      .toggle-area {
        justify-content: flex-start;
        padding-left: 0;
      }
      .content-area-container {
        flex-direction: row;
        padding-left: 0;
        gap: 0;
        align-items: flex-start;
      }
    }
  }
}

[data-theme="business"] {
  .hero-scene {
    background-image: url('/assets/images/business-mobile-bg.png');
    background-position: top;
    height: 572px;

    @include for-vertical-tablet-up {
      height: 505px;
      background-image: url('/assets/images/business-desktop-bg.png');
      background-position: 20% center;
      .hero-scene-cover {
        .grid-container {
          padding-right: 0;
        }
      }
    }

    @include for-desktop-up {
      background-position: bottom;
      height: 749px;
    }
  }
}
