@import "src/styles/variables";

.button {
  padding: 12px 24px;
  border: 1px solid $dark-grey;
  border-radius: 400px;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: $font-regular;
  &:hover {
    background: $green;
    border: 1px solid transparent;
  }

  &.secondary {
    padding: 0;
    border: 0;

    &:hover {
      background: transparent;
      border: 0;
    }
  }
}

[data-theme="business"] {
  .button {
    &:hover {
      background: $purple;
    }

    &.secondary {
      background: transparent;
    }
  }
}
