@import "src/styles/variables";
@import "src/styles/mixins";

.future-carousel-container {
  padding: 80px 0;
  background-color: $light-grey;
  .future-title {
    font-size: 28px;
    line-height: 30.8px;
    letter-spacing: -1px;
    text-align: center;
  }
  .future-description {
    margin: 30px auto;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    max-width: 344px;
  }
  .slider-area {
    position: relative;
    z-index: 5;
  }
}

@include for-vertical-tablet-up {
  .future-carousel-container {
    .future-title {
      font-size: 32px;
      line-height: 50px;
    }
    .future-description {
      margin-top: 8px;
      margin-bottom: 50px;
      font-size: 18px;
      line-height: 27px;
      max-width: 675px;
    }
  }
}

@include for-tablet-landscape-up {
  .slider-container {
    padding-right: 0;
  }
}

@include for-desktop-up {
  .future-carousel-container {
    .future-title {
      font-size: 40px;
      line-height: 50px;
    }
    .future-description {
      margin: 30px auto 40px;
      font-size: 20px;
      line-height: 32px;
      max-width: 1060px;
    }
  }
}
