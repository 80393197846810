.page {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  contain: paint;
  margin-top: 60px;
}

.container {
  width: 100%;
}

.loader {
  height: 32px;
  width: 32px;
  animation: fullRotate 2s infinite;
}

@keyframes fullRotate {
  100% {
    transform: rotate(360deg);
  }
}

/* hero start */

.heroContainer {
  width: 100%;
}

.heroSection {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.heroTitle {
  color: var(--Black, #181818);
  font-family: 'Averta Std', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 36px;
  margin: 10px 0 20px 0;
  text-align: center;
  width: 340px;
}

.heroSubtitle {
  color: var(--Black, #181818);
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  margin-bottom: 20px;
  text-align: center;
  width: 340px;
}

.heroMobile {
  width: 220px;
  margin: 0 100px 25px 0;
}

.heroButtons {
  position: absolute;
  right: 15px;
  bottom: 50px;
  display: flex;
  flex-direction: column;
}

.heroAppStore,
.heroPlayStore {
  width: 130px;
}

.heroAppStore {
  margin-bottom: 10px;
}

/* hero end */

/* earnings start */

.earningsSection {
  background-color: var(--Background-Gray, #f9f9fe);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.earningsTitle {
  width: 390px;
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
  letter-spacing: -0.28px;
  margin: 50px 0 20px 0;
}

.earningsSubtitle {
  width: 340px;
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  margin-bottom: 40px;
}

.earningsBold {
  color: var(--Black, #181818);
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.brandsSection {
  width: 310px;
  display: flex;
  flex-wrap: wrap;
}

.brand {
  width: 155px;
}

/* earnings end */

/* whats new start */

.newContainer {
  display: block;
  width: 100%;
  background: #dbbfff;
  position: relative;
}

.newSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.newSectionTitle {
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
  letter-spacing: -0.28px;
  margin: 30px 0;
}

.bullet {
  display: flex;
  align-items: flex-start;
  width: 330px;
  margin-bottom: 20px;
}

.bulletText {
  color: var(--Black, #181818);
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.bulletCheck {
  width: 25px;
}

.tilesMobile {
  width: 100%;
  margin: -40px 0 300px 0;
}

.slideContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.slider {
  position: absolute;
  bottom: -190px;
}

.slide {
  height: 590px;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 14px;
  background: var(--White, #fff);
  box-shadow: 3.8375px 12.79167px 15.98958px 0px rgba(39, 27, 97, 0.1);
  position: initial;
}

.slideTitle {
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
  letter-spacing: -0.28px;
  margin: 35px 0 15px 0;
}

.slideImageMobile {
  width: 280px;
  margin-bottom: 25px;
}

.slideContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
}

.slideSubtitle {
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px;
  margin-bottom: 10px;
}

.slideSubtext {
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  width: 275px;
}

.slideSubtextBold {
  color: var(--Black, #181818);
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

/* whats new end */

/* raisePay start */

.raisePaySection {
  background: var(--Background-Gray, #f9f9fe);
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.raisePayTitle {
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
  letter-spacing: -0.28px;
}

.raisePayTileContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 35px 0;
}

.raisePayTileSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 360px;
}

.raisePayTile {
  border-radius: 10.7px;
  background: var(--Background-Gray, #f9f9fe);
  box-shadow: 1.1889px 9.51118px 14.26677px 0px rgba(39, 27, 97, 0.15);
  height: 200px;
  width: 160px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.tileImage {
  width: 100%;
}

.tileContent {
  margin: 15px 12px 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tileTitle {
  color: var(--Black, #181818);
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
}

.tileText {
  color: var(--Black, #181818);
  font-family: 'Averta Std', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.06px;
}

/* raisePay end */

/* waitlist start */
.waitlistContainer {
  background: var(--White, #fff);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.waitlistTitle {
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.8px;
  letter-spacing: -0.28px;
  margin: 75px 0 15px 0;
}

.waitlistText {
  color: var(--Black, #181818);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 335px;
  margin-bottom: 40px;
}

.waitlistForm {
  display: flex;
  flex-direction: column;
}

.waitlistInput {
  width: 280px;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  font-family: 'Averta Std', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.005em;
  border-radius: 100px;
  border: 1px solid #b6b6b6;
  background: var(--Background-Gray, #f9f9fe);
  margin-bottom: 15px;
}

.waitlistInput:focus {
  border: 1px solid #01d6a2;
  outline: transparent;
}

.waitlistInput::placeholder {
  color: #656565;
}

.waitlistButton {
  width: 330px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #01d6a2;
  border-radius: 75px;
  font-family: 'Averta Std', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 0px;
  color: #ffffff;
  border: transparent;
  margin-bottom: 40px;
  cursor: pointer;
}

.waitlistButton:disabled {
  background: #f5f5f5;
  color: #909090;
  cursor: not-allowed;
}

.waitlistSuccess {
  font-family: 'Averta Std', sans-serif;
  color: var(--Black, #181818);
  font-size: 16px;
  margin-bottom: 75px;
}

/* waitlist end */

/* footer start */

.footerContainer {
  background: var(--Black, #181818);
  width: 100%;
}

.footerSection {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.footerIconsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footerTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--White, #fff);
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 40px 0 20px 0;
}

.footerStores {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.footerStore {
  width: 145px;
  margin: 0 10px;
}

.footerLinksCol {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.footerLinks {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerCompany {
  width: 310px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 30px;
}

.footerCompanyTitle {
  color: var(--White, #fff);
  font-family: 'Averta Std', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.5px;
  letter-spacing: 0.15px;
}

.footerCompanyLink {
  color: var(--White, #fff);
  font-family: 'Averta Std', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 210%;
  letter-spacing: 0.15px;
}

.footerFollows {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerFollowLinks {
  width: 310px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  flex-direction: column;
}

.footerFollowTitle {
  color: var(--White, #fff);
  font-family: 'Averta Std', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 250%; /* 37.5px */
  letter-spacing: 0.15px;
}

.footerFollowIcons {
  display: flex;
}

.footerFollowImage {
  width: 22px;
  height: 22px;
  margin-right: 25px;
}

.footerCopyContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}
.footerCopy {
  width: calc(100% - 28px);
  color: #b4b4b4;
  text-align: center;
  font-family: 'Averta Std', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

/* footer end */

.tablet,
.desktop {
  display: none !important;
}

.mobile {
  display: flex !important;
}

@media only screen and (min-width: 768px) {
  .mobile {
    display: none !important;
  }

  .tablet {
    display: flex !important;
  }

  .page {
    width: 100vw;
    height: initial;
  }

  /* hero start */

  .heroContainer {
    margin: 70px 75px 150px 75px;
  }

  .heroSection {
    align-items: flex-start;
  }

  .heroTitle {
    color: var(--Black, #181818);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 55.2px;
    width: 550px;
    text-align: left;
  }

  .heroSubtitle {
    color: var(--Black, #181818);
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.5px;
    width: 420px;
    text-align: left;
    margin-bottom: 35px;
  }

  .heroButtons {
    position: initial;
  }

  .heroAppStore,
  .heroPlayStore {
    width: 160px;
  }

  .heroTablet {
    position: absolute;
    bottom: -150px;
    right: 150px;
    width: 320px;
  }

  /* hero end */

  /* earnings start */

  .earningsTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38.4px;
    letter-spacing: -0.32px;
  }

  .earningsSubtitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 630px;
  }

  .earningsBold {
    color: var(--Black, #181818);
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  .brandsSection {
    width: 630px;
  }

  /* earnings end */

  /* whats new start */

  .newSectionTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38.4px;
    letter-spacing: -0.32px;
  }

  .bullet {
    width: 550px;
  }

  .bulletText {
    color: var(--Black, #181818);
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .bulletCheck {
    width: 35px;
  }

  .tilesTablet {
    width: 100%;
    margin: 30px 0 200px 0;
  }

  .slider {
    bottom: -200px;
  }

  .slide {
    width: 690px;
    height: 475px;
    position: relative;
  }

  .slideTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.32px;
    margin-top: 55px;
  }

  .slideContentContainer {
    align-items: flex-start;
    width: 95%;
    margin: 50px 0 0 85px;
  }

  .slideSubtitle {
    color: var(--Black, #181818);
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.8px;
    text-align: left;
  }

  .slideSubtext {
    color: var(--Black, #181818);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .slideSubtextBold {
    color: var(--Black, #181818);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  .slideImageTablet {
    position: absolute;
    bottom: 70px;
    right: 50px;
    width: 300px;
  }

  /* whats new end */

  /* raisePay start */

  .raisePaySection {
    padding-top: 300px;
  }

  .raisePayTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.32px;
  }

  .raisePayTileContainer {
    margin: 20px 0 90px 0;
  }

  .raisePayTileSection {
    width: 750px;
  }

  .raisePayTile {
    width: 165px;
  }

  .tileContent {
    margin: 15px 17px 10px 15px;
  }

  /* raisePay end */

  /* waitlist start */

  .waitlistTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 39.6px;
    letter-spacing: -0.36px;
  }

  .waitlistText {
    width: 600px;
    color: var(--Black, #181818);
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.5px;
  }

  .waitlistForm {
    flex-direction: row;
    margin-bottom: 0;
  }

  .waitlistInput {
    width: 240px;
    padding: 0 30px;
    margin-right: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }

  .waitlistButton {
    width: 200px;
    color: var(--grayscale-000, var(--White, #fff));
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 0px;
  }

  .headerLinksContainer {
    display: none;
  }

  .waitlistSuccess {
    font-size: 18px;
  }

  /* waitlist end */

  /* footer start */

  .footerIconsContainer {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% - 100px);
    margin: 50px;
  }

  .footerTitle {
    margin: 0 0 20px 0;
    color: var(--White, #fff);
    text-align: right;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.8px;
    width: 320px;
  }

  .footerDownloadSection {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .footerStores {
    width: 170px;
    flex-direction: column;
  }

  .footerStore {
    width: 170px;
    margin: 0 0 10px 0;
  }

  .footerCompanyTitle {
    color: var(--White, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.16px;
  }

  .footerCompanyLink {
    color: var(--White, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 250%;
    letter-spacing: 0.16px;
  }

  .footerFollowTitle {
    color: var(--White, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.16px;
    margin-bottom: 20px;
  }

  .footerFollowImage {
    width: 30px;
    height: 30px;
  }

  .footerCopy {
    width: calc(100% - 70px);
    color: #b4b4b4;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.4px;
  }

  /* footer end */
}

@media only screen and (min-width: 1150px) {
  .mobile {
    display: none !important;
  }

  .tablet {
    display: none !important;
  }

  .desktop {
    display: flex !important;
  }

  .container {
    transform: none;
  }

  /* hero start */

  .heroContainer {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .heroSection {
    width: 1440px;
  }

  .heroTitle {
    color: var(--Black, #181818);
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 74.4px;
    letter-spacing: -0.62px;
    margin: 150px 0 25px 17%;
    width: 625px;
  }

  .heroSubtitle {
    color: var(--Black, #181818);
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.5px;
    margin-left: 17%;
  }

  .heroQrCode {
    margin-left: 17%;
    margin-top: 50px;
    width: 130px;
  }

  .heroSubtext {
    color: var(--Black, #181818);
    font-family: 'Averta Std', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.11px;
    margin: 20px 0 150px 17%;
  }

  .highlight {
    color: var(--primary-sea-green-500-primary, var(--Black, #01d6a2));
  }

  .heroDesktop {
    width: 450px;
    position: absolute;
    right: 7%;
    bottom: 70px;
  }

  /* hero end */

  /* earnings start */

  .earningsTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 57.6px;
    letter-spacing: -0.48px;
    width: auto;
    margin-bottom: 15px;
  }

  .earningsSubtitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 685px;
  }

  .earningsBold {
    color: var(--Black, #181818);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  .brandsSection {
    width: 925px;
    justify-content: center;
  }

  .brand {
    width: 220px;
  }

  /* earnings end */

  /* whats new start */

  .newContainer {
    width: 100%;
    padding-bottom: 500px;
  }

  .newSection {
    max-width: 1440px;
    margin: 0 0 0 45%;
    align-items: flex-start;
  }

  .newSectionTitle {
    margin-top: 180px;
    color: var(--Black, #181818);
    text-align: center;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.46px;
  }

  .bullet {
    width: 575px;
  }

  .bulletCheck {
    width: 35px;
  }

  .bulletText {
    color: var(--Black, #181818);
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
  }
  .tilesDesktop {
    position: absolute;
    top: 0;
    left: -81.8%;
    width: 600px;
  }

  .slider {
    bottom: -170%;
    left: -62%;
  }

  .slide {
    width: 1080px;
    height: 700px;
  }

  .slideTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.48px;
    margin: 70px 0 85px 0;
  }

  .slideImageTablet {
    width: 490px;
    bottom: 60px;
    right: 95px;
  }

  .slideContentContainer {
    margin-left: 125px;
  }

  .slideSubtitle {
    color: var(--Black, #181818);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 20px;
  }

  .slideSubtext {
    color: var(--Black, #181818);
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 31.5px */
    width: 400px;
  }

  .slideSubtextBold {
    color: var(--Black, #181818);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  /* whats new end */

  /* raisePay start */

  .raisePaySection {
    padding-top: 550px;
  }

  .raisePayTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    letter-spacing: -0.48px;
    margin-bottom: 30px;
  }

  .raisePayTileSection {
    width: 1080px;
  }

  .raisePayTile {
    width: 250px;
    height: 315px;
    margin-right: 20px;
  }

  .tileContent {
    margin: 25px 18px 10px 30px;
  }

  .tileTitle {
    color: var(--Black, #181818);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 27.883px;
  }

  .tileText {
    color: var(--Black, #181818);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  /* raisePay end */

  /* waitlist start */

  .waitlistContainer {
    margin: 140px 0;
  }

  .waitlistTitle {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 52.8px */
    letter-spacing: -0.48px;
    margin-bottom: 25px;
  }

  .waitlistText {
    color: var(--Black, #181818);
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 50px;
    width: 870px;
  }

  .waitlistInput {
    width: 290px;
    height: 50px;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.08px;
  }

  .waitlistInput::placeholder {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.08px;
  }

  .waitlistButton {
    width: 240px;
    height: 50px;
    color: var(--grayscale-000, var(--White, #fff));
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 0px;
  }

  .waitlistSuccess {
    font-size: 16px;
  }

  /* waitlist end */

  /* footer start */

  .footerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .footerSection {
    max-width: 1080px;
  }

  .footerIconsContainer {
    margin: 30px 0;
  }

  .footerDownloadSection {
    align-items: center;
  }

  .footerTitle {
    color: var(--White, #fff);
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    width: 215px;
  }

  .footerStores {
    width: 100%;
    align-items: center;
  }

  .footerStore {
    width: 160px;
  }

  .footerLinksCol {
    flex-direction: row;
  }

  .footerLinks {
    width: 30%;
  }

  .footerCompany {
    width: 100%;
  }

  .footerFollowLinks {
    width: 100%;
    margin-left: 100px;
  }

  .footerFollows {
    align-items: flex-start;
  }

  .footerFollowTitle {
    margin-bottom: 10px;
  }

  .footerCopyContainer {
    justify-content: flex-start;
  }

  .footerCopy {
    width: 760px;
    text-align: left;
    margin-top: -200px;
  }

  /* footer end */
}

@media only screen and (min-width: 1440px) {
  .tilesDesktop {
    left: -648px;
  }

  .slider {
    left: -492px;
  }
}
