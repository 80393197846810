@import "src/styles/variables";
@import "src/styles/mixins";

.toggle-button {
  border: none;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0, 0.2) inset;
  background-color: $white;
  position: relative;
  display: flex;
  padding: 3px !important;
  width: 200px;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: $white !important;
    border: none;
  }

  .toggle-item {
    border-radius: 0;
    width: 100%;
    height: 100%;
    color: $mid-grey;
    font-family: $font-regular;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: color 0.4s;
    &.active {
      color: $light-grey;
    }
  }

  .highlight-item {
    z-index: 0;
    width: 100px;
    height: 42px;
    border-radius: 400px;
    background-color: $dark-grey;
    position: absolute;
    left: 3px;
    transition: left 0.5s;
    &.business {
      left: calc(50% - 3px);
    }
  }
}

@include for-desktop-up {
  .toggle-button {
    width: 304px;
    height: 74px;
    .toggle-item {
      font-size: 18px;
    }
    .highlight-item {
      width: 147px;
      height: 68px;
      &.business {
        left: calc(50% + 1px);
      }
    }
  }
}
