@import "src/styles/variables";
@import "src/styles/mixins";

.footer {
  padding-top: 38px;
  width: 100%;
  height: auto;
  position: relative;
  background-color: $white;
  z-index: 1;
  .footer-content-area {
    .raise-info-area {
      border-bottom: 1px solid $mid-grey;
      .footer-slogan {
        font-size: 22px;
        line-height: 30.8px;
        margin: 17px 0 28px;
        max-width: 310px;
      }
    }
  }

  @include for-vertical-tablet-up {
    padding-top: 40px;
    .footer-content-area {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .raise-info-area {
        border-bottom: none;
        .footer-slogan {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  @include for-desktop-up {
    .footer-content-area {
      .raise-info-area {
        .footer-slogan {
          font-size: 26px;
          line-height: 34px;
        }
      }
    }
  }
}
