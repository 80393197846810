@import "src/styles/variables";
@import "src/styles/mixins";

.hero-scene-description {
  max-width: 245px;
  margin: 0 auto;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;

  @include for-vertical-tablet-up {
    text-align: left;
    align-items: flex-start;
  }
}
